import { Component } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getIsLoadingPageVisible,
  getIsLoadingComplete,
  getLoadingMessage,
} from '../../../app.reducer';

@Component({
  selector: 'ot-loading',
  templateUrl: './loading.html',
  styleUrls: ['loading.scss']
})
export class LoadingComponent {
  public isLoadingPageVisible$: Observable<boolean> = this.store.select(getIsLoadingPageVisible);
  public isLoadingComplete$: Observable<boolean> = this.store.select(getIsLoadingComplete);
  public message$: Observable<string> = this.store.select(getLoadingMessage);
  public loadingStart$: Observable<boolean> = of(true).pipe(delay(0));
  public defaultMessage: string = 'Please wait...';

  constructor(private store: Store<any>) { }
}
