import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { RouterModule } from '@angular/router';

/*
 * Platform and Environment providers/directives/pipes
 */
import { ROUTES } from './app.routes';

// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { NoContentComponent } from './common/no-content';
// Store
import { StoreState } from './store';
import { HttpClientModule,  HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS
];

import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { fetchSettingsFactory } from './factories';
import { OktaService } from './core/services/okta/okta.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { HttpService } from './core/interceptors/http.interceptor';
import { AppService } from '../app.service';

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    NoContentComponent
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({logOnly: !isDevMode()}),
    RouterModule.forRoot(ROUTES, { useHash: false, enableTracing: true }),
    StoreState,
    HttpClientModule,
    CoreModule.forRoot(),
    EffectsModule.forRoot([AppEffects]),
    ToastrModule.forRoot({
      maxOpened: 1,
      positionClass: 'toast-top-full-width',
      tapToDismiss: false,
      timeOut: 60000,
      closeButton: true,
      extendedTimeOut: 60000,
      iconClasses: {
        error: 'ot-toast-error',
        info: 'ot-toast-info',
        success: 'ot-toast-success',
        warning: 'ot-toast-warning',
      },
      autoDismiss: true,
      onActivateTick: true,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],

  providers: [
    APP_PROVIDERS,
    AppService,
    provideEnvironmentNgxMask(),
    {
      provide: APP_INITIALIZER,
      useFactory: fetchSettingsFactory,
      deps: [AppService, OktaService],
      multi: true
    },
    {  provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true}
  ]
})
export class AppModule {}
