import { Injectable } from '@angular/core';
import { toPayload } from './util';
import {
  Actions,
  createEffect,
  ofType
} from '@ngrx/effects';
import {
  ActionTypes,
  SetSupportContacts,
  ApplicationManager,
  RecruitAuthentication,
  ClearApplicationManager,
  SetWindowScrollPosition,
  SetLoadingState,
  HideToastr,
} from './app.actions';

import { SupportContactsService } from './core/services/supportContacts/support.contacts.service';
import { ISupportContacts } from './core/services/supportContacts/interfaces';
import { ManagersService } from './core/services/managers/managers.service';
import {
  OktaService,
  OKTA_CHECK_INTERVAL,
  OKTA_INITIAL_TOKEN_CHECK
} from './core/services/okta/okta.service';
import { Store } from '@ngrx/store';
import { getScrollPosition } from './app.reducer';
import { combineLatest, switchMap, of, map, take, mergeMap, skipWhile, tap, from, catchError, timer, delay } from 'rxjs';
import { getRecruitInfo } from './common/tasks/tasks.reducer';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoadingStatusEnum, ServiceStatusEnum } from './constants';
import { IManagerProfileViewModel } from './core/services/managers/interfaces';
import {
  ActionToastrComponent
} from './core/components/customToaster/actionToastr';
import { StoreTeamManager } from './common/tasks/tasks.actions';

const toastrDuration = 60 * 60 * 1000;

@Injectable()
export class AppEffects {
  private tasksContainer: any = null;

  constructor(
    private actions$: Actions,
    protected supportContactsService: SupportContactsService,
    protected managerService: ManagersService,
    private oktaService: OktaService,
    private store: Store<any>,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  fetchSupportContacts$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.FETCH_SUPPORT_CONTACTS),
    switchMap(() => this.supportContactsService.getSupportContacts()),
    map((contacts: ISupportContacts) => new SetSupportContacts(contacts))
  ));

  getApplicationManager$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GET_MANAGER),
    map(toPayload),
    take(1),
    switchMap((id) => this.managerService.getManagerById(id)),
    mergeMap((manager: IManagerProfileViewModel) => [
      new ApplicationManager(manager),
      new StoreTeamManager(manager)
    ])
  ));

  appAuth$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RECRUIT_AUTH),
    switchMap(() => this.store.select(getRecruitInfo)),
    skipWhile(({ id }) => id === null),
    switchMap(() => {
      document.documentElement.classList.add('logged');
      return [];
    })
  ), { dispatch: false });

  setApplicationManager$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SET_MANAGER),
    map(toPayload),
    switchMap((manager) => {
      sessionStorage.setItem('applicationManager', JSON.stringify(manager));
      return [];
    })
  ), { dispatch: false });

  clearApplicationManager$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CLEAR_MANAGER),
    switchMap(() => {
      sessionStorage.removeItem('applicationManager');
      return [];
    })
  ), { dispatch: false });

  goToOktaLoginPage$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GO_TO_OKTA_LOGIN_PAGE),
    tap(() => this.oktaService.goToLoginPage())
  ), { dispatch: false });

  sendAuthCode$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SEND_AUTH_CODE),
    map(toPayload),
    switchMap((payload) => this.oktaService.getOktaRefreshToken(payload)),
    tap((refreshToken: any) => localStorage.setItem('okta-refresh-token', refreshToken))
  ), { dispatch: false });

  logOutRecruit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.OKTA_LOGOUT),
    switchMap(() =>
      from(this.oktaService.logout()).pipe(
        catchError(() => of({}))
      )
    ),
    mergeMap(() => {
      localStorage.removeItem('okta-refresh-token');
      this.router.navigate(['/'], { replaceUrl: true });
      return [
        new RecruitAuthentication(false),
        new ClearApplicationManager()
      ];
    })
  ));

  forceLogOutRecruit$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.OKTA_FORCE_LOGOUT),
    switchMap(() =>
      from(this.oktaService.logout()).pipe(
        catchError(() => of({}))
      )
    ),
    tap(() => {
      localStorage.removeItem('okta-refresh-token');
      [
        new RecruitAuthentication(false),
        new ClearApplicationManager()
      ].forEach((i) => this.store.dispatch(i));
    }),
    map(() => this.forceNavigation('/'))
  ), { dispatch: false });

  openSideBar$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.TOGGLE_SIDE_BAR),
    map(toPayload),
    switchMap((isOpen) => combineLatest(
      this.store.select(getScrollPosition).pipe(take(1)),
      of(isOpen)
    )),
    map(([scrollY, isOpen]) => {
      const scrollPosition = isOpen ? window.scrollY : scrollY;
      if (!this.tasksContainer) {
        this.tasksContainer = document.getElementsByClassName('tasks')[0];
      }

      if (isOpen) {
        this.emulateScroll(isOpen, scrollPosition);
        document.documentElement.classList.add('sidebar-open');
        document.documentElement.classList.add('with-overlay');
      } else {
        document.documentElement.classList.remove('sidebar-open');
        document.documentElement.classList.remove('with-overlay');
        setTimeout(this.emulateScroll.bind(this, isOpen, scrollPosition), 99);
      }

      return new SetWindowScrollPosition(scrollPosition);
    })
  ));

  showToastr$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SHOW_TOASTR),
    map(toPayload),
    tap((payload) => this.toastr[payload.type](payload.message))
  ), { dispatch: false });

  hideLoadingBar$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SHOW_TOASTR),
    map(() => new SetLoadingState({
      status: LoadingStatusEnum.hidden,
      message: '',
    }))
  ));

  showActionToastr$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SHOW_ACTION_TOASTR),
    map(toPayload),
    tap((payload) => this.toastr[payload.type](payload.message, payload.btnTitle , {
      timeOut: toastrDuration,
      extendedTimeOut: toastrDuration,
      toastComponent: ActionToastrComponent,
      onAction: this.getOnAction(payload.action),
    }))
  ), { dispatch: false });

  hideToastr$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.HIDE_TOASTR),
    tap(() => this.toastr.clear())
  ), { dispatch: false });

  setRefreshTokenTimer$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SET_REFRESH_TOKEN_TIMER),
    switchMap(() => timer(OKTA_INITIAL_TOKEN_CHECK, OKTA_CHECK_INTERVAL).pipe(
      switchMap(() => this.oktaService.isAuthenticated().pipe(
        skipWhile(isAuthenticated => 
          !isAuthenticated || !this.oktaService.isTokenAboutToExpire()
        )
      )),
      mergeMap(() => this.oktaService.refreshOktaAccessToken())
    )),
    map(() => ({ type: 'NO_ACTION' })) // Dummy action to fulfill the Effect requirement
  ), { dispatch: false });

  loadingComplete$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SET_LOADING_STATE),
    map(toPayload),
    skipWhile(({ status }) => status !== LoadingStatusEnum.loadingEnd),
    delay(1500),
    map(() => new SetLoadingState({ status: LoadingStatusEnum.hidden, message: '' }))
  ));

  hideInsiderTip$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.HIDE_INSIDER_TIP),
    tap(() => localStorage.setItem('isInsiderTipViewed', 'true'))
  ), { dispatch: false });

  serviceOutage$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SET_SERVICE_STATUS),
    map(toPayload),
    skipWhile((status) => status === ServiceStatusEnum.success),
    tap(() => {
      if (window.location.href.indexOf('outage') === -1) {
        this.outAgeNavigate();
      }
    })
  ), { dispatch: false });

  private outAgeNavigate() {
    this.forceNavigation('/outage');
  }

  private forceNavigation(href, wind = window) {
    wind.location.href = href;
  }

  private getTasksContainerPosition(isOpen, scrollPosition) {
    return isOpen && scrollPosition ?
      `${scrollPosition > 64 ? -(scrollPosition) + 64 : 64 - scrollPosition}px`
      : '64px';
  }

  private emulateScroll(isOpen, scrollPosition) {
    if (this.tasksContainer) {
      this.tasksContainer.style.top = this.getTasksContainerPosition(isOpen, scrollPosition);

      window.scrollTo(0, scrollPosition);
    }
  }

  private getOnAction(action) {
    return () => {
      this.store.dispatch(new HideToastr());
      this.store.dispatch(action);
    };
  }
}
