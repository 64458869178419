/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AbstractServerInteractionService, combineHeadersOptions,
  commonErrorHandlingDisabled
} from '../abstract/abstract.server.interaction.service';
import { of, catchError } from 'rxjs';
import { AppService } from '../../../../app.service';


@Injectable()
export class RequestDeviceService extends AbstractServerInteractionService {
  protected get url(): {
    requests: Function,
    status: Function,
    options: string
  } {
    return this.appService.getApiUrls().device;
  }

  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getDeviceRequest(recruitId) {
    return this.http.get(this.url.status(recruitId), {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
      .pipe(catchError(() => of(false)));
  }

  public cancelDeviceRequest(requestId) {
    return this.http.delete(this.url.requests(requestId));
  }

  public submitDeviceRequest(address) {
    return this.http.post(this.url.requests(), address);
  }

  public editDeviceRequest(requestId, address) {
    return this.http.put(this.url.requests(requestId), address);
  }

  public getOptions(country) {
    return this.http.get(this.url.options, { params: { country } });
  }
}
