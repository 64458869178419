import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store/index';
import {
  ActionTypes,
  TypeAction
} from './request-device.actions';
import {
  DeviceRequestStatusEnum,
  RequestFormModesEnum
} from './constants';
import {
  createSelectModelFromAnyArray,
  createSelectModelFromStringArray,
  ICreateSelectModelPropsSetting
} from '../../../shared/components/select/models/createSelectModel';

export interface IDeliveryAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  stateId?: string;
  zip: string;
}

export interface IDeliveryAddressFormData {
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateId: string;
  zip: string;
}

export interface IState {
  id: number | null;
  status: DeviceRequestStatusEnum | null;
  formMode: RequestFormModesEnum | null;
  requestCheckboxValue: number | null;
  deliveryAddress: IDeliveryAddress;
  formData: IDeliveryAddressFormData;
  selectOptions: {
    state: any[];
  };
  queryState: string;
}

const initialState: IState = {
  id: null,
  status: null,
  formMode: null,
  requestCheckboxValue: null,
  deliveryAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
  },
  formData: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateId: '',
    zip: ''
  },
  selectOptions: {
    state: []
  },
  queryState: ''
};

export function requestDeviceReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_DEVICE_REQUEST: {
      const {
        id,
        status,
        deliveryAddress,
      } = payload;

      return Object.assign({}, state, {
        id,
        status,
        deliveryAddress,
        formMode: RequestFormModesEnum.view
      });
    }
    case ActionTypes.PRE_POPULATE_REQUEST_FORM: {
      const address = Object.assign({}, state.deliveryAddress);

      return Object.assign({}, state, { formData: address });
    }
    case ActionTypes.STORE_SELECT_OPTIONS: {
      return Object.assign({}, state, { selectOptions: payload });
    }
    case ActionTypes.SEARCH_STATE: {
      return Object.assign({}, state, { queryState: payload });
    }
    case ActionTypes.SET_DELIVERY_ADDRESS: {
      return Object.assign({}, state, { deliveryAddress: payload });
    }
    case ActionTypes.UPDATE_DEVICE_REQUEST_CHECKBOX: {
      return Object.assign({}, state, { requestCheckboxValue: payload });
    }
    case ActionTypes.UPDATE_DEVICE_REQUEST_FORM_DATA: {
      const { formData }: IState = state;
      const result = Object.assign({}, formData, { [payload.fieldId]: payload.value });

      return Object.assign({}, state, { formData: result });
    }
    case ActionTypes.UPDATE_REQUEST_FORM_MODE: {
      return Object.assign({}, state, { formMode: Number(payload) });
    }
    case ActionTypes.RESET_DEVICE_REQUEST_FORM: {
      const result = {
        ...initialState,
        selectOptions: {
          ...state.selectOptions
        }
      };

      return Object.assign({}, result);
    }

    default: {
      return state;
    }
  }
}

export const getRequestId = createSelector(
  (root: IRootState): IState => root.requestDevice,
  ({ id }: IState): number => id
);

export const getDeliveryAddressFormData = createSelector(
  (root: IRootState): IState => root.requestDevice,
  ({ formData }: IState): IDeliveryAddressFormData => formData
);

export const getRequestCheckboxValue = createSelector(
  (root: IRootState): IState => root.requestDevice,
  ({ requestCheckboxValue }: IState): number => requestCheckboxValue
);

export const getDeliveryAddress = createSelector(
  (root: IRootState): IState => root.requestDevice,
  ({ deliveryAddress }: IState): IDeliveryAddress => deliveryAddress
);

export const getDeviceRequestStatus = createSelector(
  (root: IRootState): IState => root.requestDevice,
  ({ status }: IState): DeviceRequestStatusEnum => status
);

export const getRequestDeviceFormMode = createSelector(
  (root: IRootState): IState => root.requestDevice,
  ({ formMode }: IState): RequestFormModesEnum => formMode
);

export const getDeviceSelectOptions = createSelector(
  (root: IRootState): IState => root.requestDevice,
  ({ selectOptions }: IState): any => {
    const selectSetting: ICreateSelectModelPropsSetting = {
      valueKey: 'value',
      labelKey: 'text'
    };

    return Object.keys(selectOptions).reduce((acc, key) => {
      acc[key] = typeof selectOptions[key][0] !== 'object' ?
        createSelectModelFromStringArray(selectOptions[key]) :
        createSelectModelFromAnyArray(selectOptions[key], selectSetting);

      return acc;
    }, Object.assign({}, selectOptions));
  }
);

export const getFilteredStates = createSelector(
  (root: IRootState): IState => root.requestDevice,
  (state: IState): any[] => {
    const {
      selectOptions: { state: countryStates },
      queryState
    } = state;

    return countryStates
      .filter((countryState) => countryState.text
        .toLowerCase()
        .includes(queryState.toLowerCase()))
      .map(({ value, text }) => ({ value, label: text }));
  }
);
