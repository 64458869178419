import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IDeliveryAddress, IDeliveryAddressFormData } from './request-device.reducer';
import { RequestFormModesEnum } from './constants';
import { IFormInputChangePayload } from '../../abstract.components/abstract.form.component';

export const ActionTypes = {
  FETCH_DEVICE_REQUEST: type('[Device] fetch request'),
  STORE_DEVICE_REQUEST: type('[Device] set request'),
  PRE_POPULATE_REQUEST_FORM: type('[Device] pre populate form'),
  SET_DELIVERY_ADDRESS: type('[Device] set delivery address'),
  UPDATE_DEVICE_REQUEST_CHECKBOX: type('[Device] update device request radiobutton'),
  UPDATE_DEVICE_REQUEST_FORM_DATA: type('[Device] update device request form'),
  UPDATE_REQUEST_FORM_MODE: type('[Device] update form mode'),
  DEFINE_PROPER_FORM_MODE: type('[Device] define proper form mode'),
  DECLINE_DEVICE: type('[Device] decline device'),
  CANCEL_DEVICE_REQUEST: type('[Device] cancel device request'),
  SUBMIT_DEVICE_REQUEST: type('[Device] submit device request'),
  RESET_DEVICE_REQUEST_FORM: type('[Device] reset request form'),
  FETCH_SELECT_OPTIONS: type('[Device] fetch select options'),
  STORE_SELECT_OPTIONS: type('[Device] store select options'),
  SEARCH_STATE: type('[Device] search state'),
};

export class FetchDeviceRequest implements Action {
  public type = ActionTypes.FETCH_DEVICE_REQUEST;

  constructor(public payload?: any) {}
}

export class StoreDeviceRequest implements Action {
  public type = ActionTypes.STORE_DEVICE_REQUEST;

  constructor(public payload: {status: number, id: any, deliveryAddress: any }) {}
}

export class PrepopulateRequestForm implements Action {
  public type = ActionTypes.PRE_POPULATE_REQUEST_FORM;

  constructor(public payload?: any) {}
}

export class SetDeliveryAddress implements Action {
  public type = ActionTypes.SET_DELIVERY_ADDRESS;

  constructor(public payload: IDeliveryAddress) {}
}

export class UpdateRequestCheckbox implements Action {
  public type = ActionTypes.UPDATE_DEVICE_REQUEST_CHECKBOX;

  constructor(public payload: number) {}
}

export class UpdateRequestFormMode implements Action {
  public type = ActionTypes.UPDATE_REQUEST_FORM_MODE;

  constructor(public payload: RequestFormModesEnum) {}
}

export class DefineProperFormMode implements Action {
  public type = ActionTypes.DEFINE_PROPER_FORM_MODE;

  constructor(public payload?: any) {}
}

export class DeclineDevice implements Action {
  public type = ActionTypes.DECLINE_DEVICE;

  constructor(public payload?: any) {}
}

export class CancelDeviceRequest implements Action {
  public type = ActionTypes.CANCEL_DEVICE_REQUEST;

  constructor(public payload?: any) {}
}

export class SubmitDeviceRequest implements Action {
  public type = ActionTypes.SUBMIT_DEVICE_REQUEST;

  constructor(public payload: IDeliveryAddressFormData) {}
}

export class FormInputUpdate implements Action {
  public type = ActionTypes.UPDATE_DEVICE_REQUEST_FORM_DATA;

  constructor(public payload: IFormInputChangePayload) {}
}

export class ResetRequestForm implements Action {
  public type = ActionTypes.RESET_DEVICE_REQUEST_FORM;

  constructor(public payload?: any) {}
}

export class FetchDeviceSelectOptions implements Action {
  public type = ActionTypes.FETCH_SELECT_OPTIONS;

  constructor(public payload?: any) {}
}

export class StoreDeviceSelectOptions implements Action {
  public type = ActionTypes.STORE_SELECT_OPTIONS;

  constructor(public payload?: any) {}
}

export class DeviceSearchState implements Action {
  public type = ActionTypes.SEARCH_STATE;

  constructor(public payload: string) {}
}

export type TypeAction =
  FetchDeviceRequest |
  StoreDeviceRequest |
  UpdateRequestFormMode |
  DefineProperFormMode |
  DeclineDevice |
  CancelDeviceRequest |
  SubmitDeviceRequest |
  FormInputUpdate |
  ResetRequestForm |
  StoreDeviceSelectOptions |
  FetchDeviceSelectOptions |
  StoreDeviceSelectOptions |
  DeviceSearchState |
  PrepopulateRequestForm |
  SetDeliveryAddress |
  UpdateRequestCheckbox;
