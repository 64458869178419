import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { FetchSupportContacts, ApplicationManager, SetInsiderTipVisibilityStatus } from './app.actions';
import { getIsLogged } from './app.reducer';
import { skipWhile } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { sendGoogleAnalyticsPageChange } from './util';
import { i9TaskUrl } from './common/implicit/i9/implicit-i9.component';
import { I9RedirectCompleted } from './common/implicit/i9/implicit-i9.actions';

declare const parent: Window; // Define parent as a Window object

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public isLogged$ = this.store.select(getIsLogged);

  @HostListener('document:touchstart', ['$event']) onDocumentTouch() {
    return true;
  }

  constructor(private store: Store<any>, private router: Router) {}

  public ngOnInit() {
    const manager = JSON.parse(sessionStorage.getItem('applicationManager'));
    const isInsiderTipViewed = !!JSON.parse(localStorage.getItem('isInsiderTipViewed'));

    if (manager) {
      this.store.dispatch(new ApplicationManager(manager));
    }

    this.store.dispatch(new SetInsiderTipVisibilityStatus(isInsiderTipViewed));
    this.store.dispatch(new FetchSupportContacts());

    this.router.events
      .pipe(
        skipWhile((event) => !(event instanceof NavigationEnd))
      )
      .subscribe((event: NavigationEnd) => {
        window.scrollTo(0, 0);

        sendGoogleAnalyticsPageChange(event.urlAfterRedirects);
      });

    parent.postMessage(new I9RedirectCompleted(), i9TaskUrl);
  }
}