import { HttpService } from './core/interceptors/http.interceptor';
import { Store } from '@ngrx/store';
import { OktaService } from './core/services/okta/okta.service';
import { AppService } from '../app.service';
import { catchError, throwError } from 'rxjs';

export const httpInterceptorFactory = (

  store: Store<any>,
  oktaService: OktaService
) => {
  return new HttpService(store, oktaService);
};

export const fetchSettingsFactory = (
  appService: AppService,
  oktaService: OktaService
) => {
  return () => new Promise((resolve, reject) => {
    appService.fetchSettings()
      .pipe(catchError((error) => {
        reject(error);
        return throwError(() => error);
      }))
      .subscribe((settings: any) => {
        window['getAppSettings'] = () => settings;
        oktaService.initOktaAuth(settings.okta);
        appService.loadAvailabilityStatus().toPromise().then()
          .catch((error) => {
            reject(error);
          });
        resolve(true);
      });
  });
};
