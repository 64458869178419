import { Component  } from '@angular/core';
import { Toast } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'ot-action-toaster',
  templateUrl: './action.toastr.html',
  styleUrls: ['action.toastr.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          opacity: 0,
        }),
      ]))),
    ]),
  ]
})
export class ActionToastrComponent extends Toast {
  public options: any;
  public defaultBtnText: string = 'Try again';

  public onAction() {
    this.options.onAction();
  }
}
