export enum DeviceRequestStatusEnum {
  pendingApproval = 0,
  approved = 1,
  rejected = 2
}

export enum RequestFormModesEnum {
  initial = 0,
  declined = 1,
  edit = 2,
  view = 3
}
