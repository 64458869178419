import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  HeaderComponent,
  FooterComponent,
  SpinnerComponent,
  LoadingComponent,
  ActionToastrComponent,
} from './components';

import { ManagersService } from './services/managers/managers.service';
import { ContactsService } from './services/contacts/contacts.service';
import { SchoolsService } from './services/schools/schools.service';
import { AgreementService } from './services/agreement/agreement.service';
import { SupportContactsService } from './services/supportContacts/support.contacts.service';
import { TasksService } from './services/tasks/tasks.service';
import { ProfilePhotoService } from './services/profilePhoto/profilePhoto.service';
import { DriverLicenseService } from './services/driverLicense/driverLicense.service';
import { ProfileService } from './services/profile/profile.service';
import { RequestDeviceService } from './services/requestDevice/requestDevice.service';
import { ApparelsService } from './services/apparels/apparels.service';
import { TaxDocumentsService } from './services/tax-documents/tax-documents.service';
import { WorkdayAccountService } from './services/workday-account/workday-account.service';
import { I9FormService } from './services/i-9-form/i-9-form.service';
import { DirectDepositService } from './services/directDeposit/directDeposit.service';
import { BackgroundCheckService } from './services/background-check/background-check.service';
import { OktaService } from './services/okta/okta.service';
import { LegalTrainingsService } from './services/legal-trainings/legal-trainings.service';
import { EmployeeService } from './services/employee/employee.service';
import { AccountsService } from './services/accounts/accounts.service';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MediaService } from './services/media/media.service';
import { OptionsService } from './services/options/options.service';
import { PasswordPolicyService } from './services/password-policy/passwordPolicy.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    LoadingComponent,
    ActionToastrComponent,
  ],
  exports:      [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    LoadingComponent,
    ActionToastrComponent
  ]
})
export class CoreModule {
  public static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ManagersService,
        ContactsService,
        SchoolsService,
        AgreementService,
        SupportContactsService,
        TasksService,
        ProfilePhotoService,
        DriverLicenseService,
        ProfileService,
        RequestDeviceService,
        ApparelsService,
        TaxDocumentsService,
        WorkdayAccountService,
        I9FormService,
        DirectDepositService,
        BackgroundCheckService,
        OktaService,
        LegalTrainingsService,
        EmployeeService,
        AccountsService,
        MediaService,
        OptionsService,
        PasswordPolicyService
      ]
    };
  }

  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
