import { StoreModule } from '@ngrx/store';
import {
  appReducer,
  State as appState
} from '../app.reducer';
import {
  applyFormReducer,
  State as applyFormState
} from '../common/apply-form/apply-form.reducer';
import {
  applyNowReducer,
  State as applyNowState
} from '../common/apply-now/apply-now.reducer';
import {
  agreementReducer,
  State as agreementState
} from '../common/agreement/agreement.reducer';
import {
  tasksReducer as tasksSideBarReducer,
  IState as ITasksSideBarState
} from '../common/tasks/tasks.reducer';
import {
  errorReducer as errorPageReducer,
  State as IErrorPageState
} from '../common/error/error.reducer';
import {
  directDepositReducer as directDepositTaskReducer,
  IState as IDirectDepositTaskState
} from '../common/tasks/direct-deposit/direct-deposit.reducer';
import {
  taxDocumentReducer,
  IState as ITaxDocumentsState
} from '../common/tasks/tax-document/tax-document.reducer';
import {
  profileReducer,
  IState as IProfile
} from '../common/tasks/profile/profile.reducer';
import {
  confirmPhotoReducer,
  IState as IConfirmPhotoState
} from '../common/tasks/confirm-photo/confirm-photo.reducer';
import {
  driverLicenseReducer,
  IState as IDriverLicenseState
} from '../common/tasks/driver-license/driver-license.reducer';
import {
  requestDeviceReducer,
  IState as IRequestDeviceState
} from '../common/tasks/request-device/request-device.reducer';
import {
  requestApparelsReducer,
  IState as IRequestApparelsState
} from '../common/tasks/request-apparels/request-apparels.reducer';
import {
  workdayAccountReducer,
  IState as IWorkdayAccountState
} from '../common/tasks/workday-account/workday-account.reducer';
import {
  agreementTaskReducer,
  IState as IAgreementTaskState
} from '../common/tasks/tasks-agreement/tasks-agreement.reducer';
import {
  backgroundCheckReducer,
  IState as IBackgroundCheckState
} from '../common/tasks/background-check/background-check.reducer';
import {
  i9FormReducer,
  IState as II9FormState
} from '../common/tasks/i-9-form/i-9-form.reducer';
import {
  legalTrainingsReducer,
  IState as ILegalTrainingsState
} from '../common/tasks/legal-trainings/legal-trainings.reducer';
import {
  workdayAccountErrorReducer,
  IState as IWorkdayAccountErrorState
} from '../common/tasks/workday-account/workday-account-error/workday-account-error.reducer';
import {
  workdayRehireErrorReducer,
  IState as IWorkdayRehireErrorState
} from '../common/tasks/workday-account/workday-rehire-error/workday-rehire-error.reducer';
import {
  piiInfoPopupReducer,
  IState as IPiiInfoPopupState
} from '../common/tasks/pii-info-popup/pii-info-popup.reducer';
import {
  w4Reducer,
  IState as IW4State
} from '../common/tasks/tax-document-w4/tax-document-w4.reducer';
import {
  introductionPopupReducer,
  IState as IIntroPopupState
} from '../common/tasks/introduction-popup/introduction-popup.reducer';
import {
  guidanceReducer,
  IState as IGuidanceState
} from '../common/tasks/guidance/guidance.reducer';
import {
  passwordPolicyReducer,
  IState as IPasswordPolicyState
} from '../common/agreement/set-password/set-password.reducer';

export const StoreState = StoreModule.forRoot({
  applyForm: applyFormReducer,
  applyNow: applyNowReducer,
  application: appReducer,
  agreement: agreementReducer,
  tasksSideBar: tasksSideBarReducer,
  errorPage: errorPageReducer,
  directDepositTask: directDepositTaskReducer,
  taxDocuments: taxDocumentReducer,
  recruitProfile: profileReducer,
  confirmPhoto: confirmPhotoReducer,
  driverLicense: driverLicenseReducer,
  requestDevice: requestDeviceReducer,
  requestApparels: requestApparelsReducer,
  workdayAccount: workdayAccountReducer,
  agreementTask: agreementTaskReducer,
  backgroundCheck: backgroundCheckReducer,
  i9Form: i9FormReducer,
  legalTrainings: legalTrainingsReducer,
  workdayAccountError: workdayAccountErrorReducer,
  workdayRehireError: workdayRehireErrorReducer,
  piiInfo: piiInfoPopupReducer,
  w4: w4Reducer,
  introPopup: introductionPopupReducer,
  guidance: guidanceReducer,
  passwordPolicy: passwordPolicyReducer,
  });

export interface IRootState {
  applyForm?: applyFormState;
  applyNow?: applyNowState;
  application?: appState;
  agreement?: agreementState;
  tasksSideBar?: ITasksSideBarState;
  errorPage?: IErrorPageState;
  directDepositTask?: IDirectDepositTaskState;
  taxDocuments?: ITaxDocumentsState;
  recruitProfile?: IProfile;
  confirmPhoto?: IConfirmPhotoState;
  driverLicense?: IDriverLicenseState;
  requestDevice?: IRequestDeviceState;
  requestApparels?: IRequestApparelsState;
  workdayAccount?: IWorkdayAccountState;
  agreementTask?: IAgreementTaskState;
  backgroundCheck?: IBackgroundCheckState;
  i9Form?: II9FormState;
  legalTrainings?: ILegalTrainingsState;
  workdayAccountError?: IWorkdayAccountErrorState;
  workdayRehireError?: IWorkdayRehireErrorState;
  piiInfo?: IPiiInfoPopupState;
  w4?: IW4State;
  introPopup?: IIntroPopupState;
  guidance?: IGuidanceState;
  passwordPolicy?: IPasswordPolicyState;
}
