import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './workday-account.actions';
import { IRootState } from '../../../store';

export interface IFormDataWD {
  dateOfBirth: string;
  ssn: string;
  gender: string;
  ethnicity: string;
  citizenship: string;
  maritalStatus: string;
  militaryStatus: string | null;
}

export interface IState {
  accountData: IFormDataWD;
}

const initialState: IState = {
  accountData: {
    dateOfBirth: '',
    ssn: '',
    gender: '',
    ethnicity: '',
    citizenship: '',
    maritalStatus: '',
    militaryStatus: null,
  }
};

export function workdayAccountReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_WD_INFO: {
      return Object.assign({}, state, { accountData: payload });
    }
    default: {
      return state;
    }
  }
}

export const  getWorkdayAccountData = createSelector(
  ({ workdayAccount }: IRootState) => workdayAccount,
  ({ accountData }: IState): IFormDataWD => accountData
);
