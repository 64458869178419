import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getHttpPending, getSpinnerVisibilityStatus } from '../../../app.reducer';
import { Observable } from 'rxjs';

@Component({
  selector: 'ot-spinner',
  templateUrl: './spinner.html',
  styleUrls: ['spinner.scss']
})
export class SpinnerComponent {
  public isHTTPSpinnerVisible: boolean = false;
  public isSpinnerVisible$: Observable<boolean> = this.store.select(getSpinnerVisibilityStatus);
  private timeout;
  private timeoutDelay: number = 300;

  constructor(private store: Store<any>) {
    this.store.select(getHttpPending).subscribe(
      (isPending) => {
        if (isPending) {
          this.showSpinner();
        } else {
          this.hideSpinner();
        }
      }
    );
  }

  private clearSelfTimeout() {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  private showSpinner() {
    if (this.timeout) {
      this.clearSelfTimeout();
    }

    this.timeout = setTimeout(() => {
      this.isHTTPSpinnerVisible = true;
    }, this.timeoutDelay);
  }

  private hideSpinner() {
    this.clearSelfTimeout();
    this.isHTTPSpinnerVisible = false;
  }
}
