import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IFormDataWD } from './workday-account.reducer';

export const ActionTypes = {
  FETCH_WD_INFO: type('[WorkDay account] fetch account info'),
  STORE_WD_INFO: type('[WorkDay account] store account info')
};

export class FetchWorkdayAccountInfo implements Action {
  public type = ActionTypes.FETCH_WD_INFO;

  constructor(public payload?: any) {}
}

export class StoreWorkdayAccountInfo implements Action {
  public type = ActionTypes.STORE_WD_INFO;

  constructor(public payload: IFormDataWD) {}
}

export type TypeAction =
  FetchWorkdayAccountInfo |
  StoreWorkdayAccountInfo;
