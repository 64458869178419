import { Routes } from '@angular/router';
import { NoContentComponent } from './common/no-content';

export const ROUTES: Routes = [
  { path: '', loadChildren: () => import('./common/login/login.module').then(m => m.LoginModule) },
  { path: 'tasks', loadChildren: () => import('./common/tasks/tasks.module').then(m => m.TasksModule) },
  { path: 'apply-form/:id', loadChildren: () => import('./common/apply-form/apply-form.module').then(m => m.ApplyFormModule) },
  { path: 'apply-now/:id', loadChildren: () => import('./common/apply-now/apply-now.module').then(m => m.ApplyNowModule) },
  { path: 'agreement/:id', loadChildren: () => import('./common/agreement/agreement.module').then(m => m.AgreementModule) },
  { path: 'login', loadChildren: () => import('./common/login/login.module').then(m => m.LoginModule) },
  { path: 'error', loadChildren: () => import('./common/error/error.module').then(m => m.ErrorModule) },
  { path: 'implicit', loadChildren: () => import('./common/implicit/implicit.module').then(m => m.ImplicitModule) },
  { path: 'dev', loadChildren: () => import('./common/development/development.module').then(m => m.DevelopmentModule) },
  { path: 'outage', loadChildren: () => import('./common/outage/outage.module').then(m => m.OutageModule) },
  { path: '**', component: NoContentComponent }
];
